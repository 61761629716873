import React from 'react';
import Home from './pages/home/home.js';

export default function App() {

  return (
    <div>
      <Home />
    </div>
  )
}